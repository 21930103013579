<main>
    <h1>Settings</h1>

    <section>
        <nav class="nav nav-pills nav-justified">
            <a class="nav-link" [class.active]="selectedTab === 0" (click)="selectedTab = 0">Agent Profile</a>
            <!-- <a class="nav-link" [class.active]="selectedTab === 1" (click)="selectedTab = 1">Plan & Billing</a> -->
            <a *ngIf="this.localBusinessDetails.agent_phone_number" class="nav-link" [class.active]="selectedTab === 1"
                (click)="selectedTab = 1">Store Knowledge Base</a>
        </nav>

        <div class="profile" *ngIf="selectedTab === 0">
            <form [formGroup]="myForm">
                <div class="form">
                    <div>
                        <label for="">{{language.business_name}}</label>
                        <input type="text" [(ngModel)]="businessDetails.business_name"
                            [ngModelOptions]="{standalone: true}" disabled>
                    </div>
                    <div>
                        <label for="">{{language.business_address}}</label>
                        <input type="text" [(ngModel)]="businessDetails.business_address"
                            [ngModelOptions]="{standalone: true}" disabled>
                    </div>
                    <div>
                        <label for="">{{language.business_type}}</label>
                        <input type="text" [(ngModel)]="businessDetails.business_type"
                            [ngModelOptions]="{standalone: true}" disabled>
                    </div>
                    <div>
                        <label for="">{{language.business_phone}}</label>
                        <input type="tel" [(ngModel)]="businessDetails.business_phone"
                            [ngModelOptions]="{standalone: true}" disabled>
                    </div>
                    <!-- <div>
                        <label for="">{{language.poc_number}}</label>
                        <input type="tel" [(ngModel)]="businessDetails.poc_contact_number"
                            [ngModelOptions]="{standalone: true}">
                        <span>*{{language.codeerror}}<br> {{language.acceptablecodes}}</span>
                        <span style="color:red">{{phoneErrorMsg}}</span>
                    </div> -->
                    <div formArrayName="fieldArray" class="form-poc">
                        <div *ngFor="let control of fieldArray.controls; let i = index">
                            <label *ngIf="i + 1 == 1" for="field-{{ i }}">{{language.poc_number}} {{ i + 1 }} <span> (
                                    default for call transfer )</span></label>
                            <label *ngIf="i + 1 !== 1" for="field-{{ i }}">{{language.poc_number}} {{ i + 1 }}</label>
                            <input id="field-{{ i }}" [formControlName]="i">
                            <span>*{{language.codeerror}}<br> {{language.acceptablecodes}}</span>
                        </div>
                    </div>
                </div>
            </form>

            <div class="characterdiv">
                <h4>{{language.choose_agent}}</h4>
                <div class="characters">
                    <div *ngFor="let character of characters; let i = index" (click)="characterSelected(character, i)"
                        [style.border]="selectedImageIndex === i ? '1px solid #7B61FF' : 'none'">
                        <img *ngIf="selectedImageIndex === i" class="verify"
                            src="../../assets/pics/newfigma/verified.png" />
                        <img src="{{character.icon}}" />
                        <h5>{{character.agent_name}}</h5>
                        <audio style="display: none;" #audioElement
                            [src]="language.language == 'Spanish' ? character.spanish_voice : character.voice"
                            controls="true" [hidden]="selectedImageIndex !== i"></audio>
                    </div>
                </div>
            </div>

            <button (click)="updateBusinessDetails()">
                {{language.save}}
            </button>
        </div>

        <div class="profile" *ngIf="selectedTab === 1">
            <h1>{{businessDetails.business_name ? businessDetails.business_name : 'Database'}}</h1>

            <div class="csvform">
                <div *ngIf="!uploadedData" class="title">
                    <h3>{{language.upload}}</h3>
                    <a
                        href="https://s3.ap-south-1.amazonaws.com/cdn.ghc.health/180efed9-67bc-4e5c-9c75-95d7297f6e0d_Sample%20Sheet.xlsx">{{language.downloadsample}}</a>
                </div>
                <form *ngIf="!uploadedData" [ngClass]="{'has-file': uploadedFile}" (drop)="onFileDrop($event)"
                    (dragover)="onDragOver($event)">
                    <input type="file" id="fileInput" (change)="onFileSelect($event)" hidden accept=".csv, .xlsx">
                    <label *ngIf="!uploadedFile" for="fileInput">
                        <span class="material-symbols-outlined">
                            upload_file
                        </span>
                        <p>{{language.drag}}</p>
                        <hr />
                        <button type="button" (click)="triggerFileInput()">{{language.choosefile}}</button>
                    </label>
                    <label *ngIf="uploadedFile">
                        <span class="material-symbols-outlined">
                            task
                        </span>
                        <p>{{ uploadedFile.name }}</p>
                        <hr />
                        <button type="button" (click)="removeInput()">{{language.remove_file}}</button>
                    </label>
                </form>
                <div *ngIf="uploadedData" class="uploaded">
                    <h3 style="margin-bottom: 10px;">{{language.uploaded_file}}</h3>
                    <div>
                        <a href="{{uploadedData}}">{{language.download_sheet}}</a>
                        <span (click)="removeInput()">X</span>
                    </div>
                </div>
            </div>

            <button *ngIf="!uploadedData" (click)="saveCSV()">{{language.upload_btn}}</button>
        </div>

        <app-spinner [isLoading]="isLoading"></app-spinner>
    </section>
</main>