export const environment = {
    production: false,
    auth: {
        domain: 'aitabletalk.us.auth0.com',
        clientId: 'ATtXUotpNzDwrtM3czM7Q9rl6BY8gxvs',
        redirectUri: 'http://localhost:4200', //Not used
        audience: 'https://aitabletalk.us.auth0.com/api/v2/',
        apiUrl: 'https://c7zagaquzk.execute-api.ap-south-1.amazonaws.com/production',
        serverUrl: 'https://table-talk-server.tryantler.com'
    }
};